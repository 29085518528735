<!-- loading symbol overlapping whole content -->
<div id="loading-symbol-background" [hidden]="loadingHidden">
  <div class="loading-symbol">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<div *ngIf="organization && organization.state === 'demo'" id="demo-banner">
  <button type="button" class="inline-button" (click)="showDemoInfo()" translate>GENERAL.SHOW_DEMO_INFO
    <mat-icon fontSet="material-icons-outlined">chevron_right</mat-icon>
  </button>
</div>
<main>
  <header>
    <div class="header-first logo-container">
      <a id="home-btn-reportys" [routerLink]='[getHomeRedirectPath()]' class="a-primary home-btn"><img class="logo-img"
          title="Reportys logo" alt="Reportys logo" src="../assets/logo/reportys-logo.svg" />Reportys</a>
      <a id="home-btn-organization" [routerLink]='[getHomeRedirectPath()]' class="a-primary home-btn"><img
          class="logo-img" title="Reportys logo" alt="Reportys logo" src="../assets/logo/reportys-logo.svg" />{{
        (organization && isOrgNameVisible()) ? organization.name : 'Reportys' }}</a>
    </div>
    <span class="header-second">{{ (organization && isOrgNameVisible()) ? organization.name : '' }}</span>
    <div class="header-third">
      <div class="lang-switch border-right no-wrap">
        <button id="change-lang-cz" type="button" class="inline-button" (click)="changeLang('cz')"
          [disabled]="currentLang=== 'cz'">CZ</button>
        <span class="lang-divider"></span>
        <button id="change-lang-en" type="button" class="inline-button" (click)="changeLang('en')"
          [disabled]="currentLang=== 'en'">EN</button>
      </div>
      <ng-container *ngIf="account">
        <app-menu></app-menu>
      </ng-container>
      <ng-container *ngIf="!account">
        <a [routerLink]="['/login']" class="inline-button a-primary border-right no-wrap" translate>
          GENERAL.LOGIN<mat-icon fontSet="material-icons-outlined">person</mat-icon>
        </a>
        <button type="button" class="inline-button" (click)="showHelp()" translate>GENERAL.HELP
          <mat-icon fontSet="material-icons-outlined">help_outline</mat-icon>
        </button>
      </ng-container>
    </div>
  </header>
  <router-outlet></router-outlet>
  <footer>
    <div class="logo-container">
      <img class="logo-img" src="../assets/logo/reportys-logo.svg" />
      <span>{{ getFooterLabel() }}</span>
    </div>
  </footer>
</main>